// window.addEventListener('load', onVrViewLoad);
var vrDisplayer;

function onVrViewLoad(firstImage) {
  // Selector '#vrview' finds element with id 'vrview'.
  // console.log("firstImage:", firstImage);
  vrDisplayer = new VRView.Player('#vrview', {
    image: firstImage,
    is_stereo: false,
    width: '100%',
    height: '781.44',
    default_yaw: 325
  });
}

function changeVrViewContent(newImg){
  // console.log("newImg:", newImg);
  vrDisplayer.setContent({
    image: newImg,
    is_stereo: false,
    default_yaw: 360
  });
  // allHotSpot();
}

function allHotSpot(){
  vrDisplayer.addHotspot('fireplace', {
    pitch: -10, // In degrees. Up is positive.
    yaw: -37.5, // In degrees. To the right is positive.
    radius: 0.075, // Radius of the circular target in meters.
    distance: 2.5 // Distance of target from camera in meters.
  });
  
  vrDisplayer.addHotspot('wall-color', {
    pitch: -2.5, // In degrees. Up is positive.
    yaw: 10, // In degrees. To the right is positive.
    radius: 0.075, // Radius of the circular target in meters.
    distance: 2 // Distance of target from camera in meters.
  });

  vrDisplayer.addHotspot('main-room', {
    pitch: 0, // In degrees. Up is positive.
    yaw: -107.5, // In degrees. To the right is positive.
    radius: 0.1, // Radius of the circular target in meters.
    distance: 5.5 // Distance of target from camera in meters.
  });
}

function changeVrViewVideo(video){
  vrDisplayer.setContent({
    video: video,
    is_stereo: false,
    default_yaw: 360
  });
}